<template>
  <div class="mb-4 bg-white shadow" :id="category.id">
    <!-- TODO: Change to h-24, once we have images -->
    <div
      class="header h-16 sticky top-0 flex items-center font-semibold justify-center text-2xl bg-pacific-600 text-white"
    >
      {{ category.name }}
    </div>

    <menu-item
      v-for="menuItem in category.menu"
      :menuItem="menuItem"
      :priceNames="category.priceNames"
      :key="menuItem.number"
    />
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
export default {
  components: { MenuItem },
  name: "MenuCategory",
  props: ["category"]
};
</script>

<style lang="postcss">
.menu-item:nth-child(odd) {
  @apply bg-gray-100;
}
</style>
