<template>
  <div class="text-right">
    <span v-if="name" class="text-sm font-semibold text-gray-500 mr-1">
      {{ name }}
    </span>

    <span class="font-semibold text-gray-800 price">
      {{ formatPrice(price) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Price",
  props: ["price", "name"],
  methods: {
    formatPrice: function(price) {
      let formatter = Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR"
      });

      return formatter.format(price);
    }
  }
};
</script>

<style scoped>
.price {
  font-family: monospace, sans-serif;
}
</style>
