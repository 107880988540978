<template>
  <button
    href="#"
    class="fixed shadow top-1 right-1 text-xl p-2 rounded-md bg-pacific-600 hover:bg-pacific-700 z-40 focus:outline-none"
    id="notepad"
    v-on:click="showModal = true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 20 20"
      viewBox="0 0 24 24"
      fill="black"
      width="24px"
      height="24px"
      style="fill: white;"
    >
      <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
      <g>
        <path
          d="M19,3h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5 C21,3.9,20.1,3,19,3z M12,2.75c0.41,0,0.75,0.34,0.75,0.75S12.41,4.25,12,4.25s-0.75-0.34-0.75-0.75S11.59,2.75,12,2.75z M9.1,17H7 v-2.14l5.96-5.96l2.12,2.12L9.1,17z M16.85,9.27l-1.06,1.06l-2.12-2.12l1.06-1.06c0.2-0.2,0.51-0.2,0.71,0l1.41,1.41 C17.05,8.76,17.05,9.07,16.85,9.27z"
        />
      </g>
    </svg>
  </button>

  <modal v-if="showModal" @close="showModal = false">
    <template v-slot:header>Merkzettel</template>

    <menu-category
      v-for="category in data"
      :key="category.id"
      :category="category"
    />

    <span class="text-gray-800 text-lg italic" v-if="data.length === 0">
      Noch nichts gespeichert!<br />
      Füge doch etwas hinzu...
    </span>

    <!--
    <div v-else>
      <div class="flex justify-end">
        <span class="mr-2">Gesamt:</span>
        ~<price :price="orderTotal" :name="null" />
      </div>
      <div class="text-right">
        <small>
          Gesamt-Preis wird bei mehr-preisigen Artikeln am günstigsten Preis
          festgemacht
        </small>
      </div>
    </div>
    -->

    <template v-slot:footer>
      <div class="text-left grid grid-cols-2 space-x-2 justify-around">
        <div class="">
          <span class="font-semibold text-lg">Telefonnummer</span>
          <p>+49 39959 364977</p>
        </div>
        <div>
          <span class="font-semibold text-lg">Mobilnummer</span>
          <p>+49 147 8447828</p>
        </div>
      </div>

      <hr class="border-gray-300 my-2" />

      <button class="modal-default-button mr-2 rounded" @click="emptyBasket">
        Leeren
      </button>
      <button class="modal-default-button rounded" @click="showModal = false">
        Schließen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import MenuCategory from "@/components/MenuCategory.vue";

export default {
  name: "OrderList",
  props: ["menu"],
  components: {
    Modal,
    MenuCategory
  },
  data: function() {
    return {
      showModal: false,
      orderTotal: 0
    };
  },
  computed: {
    data: function() {
      let basket = this.$store.state.basket;
      let data = [];

      for (let category of this.menu) {
        let c = Object.assign({}, category);
        c.menu = category.menu.filter(i => basket[i.number]);
        c.menu.forEach(menuItem => {
          this.orderTotal += menuItem.prices[0];
        });

        if (c.menu.length > 0) {
          data.push(c);
        }
      }

      return data;
    }
  },
  methods: {
    emptyBasket: function() {
      this.$store.state.basket = [];
    }
  }
};
</script>

<style scoped>
.pulse {
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s;
}

@keyframes pulse-red {
  0% {
    background: rgba(255, 82, 82, 0.7);
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
</style>
