<template>
  <div class="container mx-auto max-w-4xl mt-8">
    <div
      id="update-available"
      class="fixed top-5 p-3 text-black mx-auto bg-white max-w-4xl w-full z-50 shadow rounded-md"
      style="visibility: hidden"
    >
      <span class="font-semibold text-xl"
        >Ein neues Update ist verfügbar. Bitte Webseite aktualisieren.</span
      >
      <hr class="border-gray-500 my-2" />
      <strong>Web:</strong> Strg + R<br />
      <strong>Mobil:</strong> Seite herunterziehen
    </div>
    <router-view />
  </div>
  <footer class="text-sm bg-pacific-700 px-3 py-1 text-white w-full shadow">
    Inhaber: Kalender Dogan
    <hr class="border-pacific-800 my-2" />
    Copyright &copy; Cedric Schmitt. Alle Rechte, Irrtümer, Änderungen und
    Druckfehler sind vorbehalten.<br />
    Kontakt:
    <a
      class="font-semibold hover:text-gray-200"
      href="mailto:mail@cedric-schmitt.de"
      >mail@cedric-schmitt.de</a
    >
  </footer>
</template>

<style lang="postcss">
body,
html {
  @apply bg-gray-100;
}
</style>
