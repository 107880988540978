<template>
  <nav
    class="flex flex-wrap mb-4 justify-center border border-gray-200 p-4 rounded-md shadow bg-white"
  >
    <a
      v-for="category in filteredMenu"
      :href="'#' + category.id"
      :key="category.id"
      class="m-1 py-2 px-4 bg-pacific-600 hover:bg-pacific-700 text-white rounded-md"
    >
      {{ category.name }}
    </a>
  </nav>

  <section>
    <div
      class="text-center text-gray-800 mb-4 border border-gray-200 p-4 rounded-md shadow bg-white"
    >
      <h2 class="text-2xl mb-2">Keine Ahnung was Sie bestellen sollen?</h2>
      <button
        class="py-2 px-4 focus:outline-none hover:bg-pacific-700 bg-pacific-600 text-white rounded-md"
        v-on:click="getRandomFoods()"
      >
        Zufälliges Gericht
      </button>
    </div>

    <modal v-if="showRandomFoodModal" @close="showRandomFoodModal = false">
      <div class="p-2 bg-pacific-600 text-white">{{ randomCategory.name }}</div>
      <menu-item
        class="border-l border-r border-b"
        :menuItem="randomFood"
        :priceNames="randomCategory.priceNames"
      ></menu-item>
    </modal>
  </section>

  <order-list :menu="menu" />

  <SwitchGroup
    as="section"
    class="flex text-gray-800 mb-4 items-center space-x-4 bg-white border border-gray-200 p-4 rounded-md shadow"
  >
    <SwitchLabel class="cursor-pointer text-2xl">Vegetarisch?</SwitchLabel>
    <Switch
      as="button"
      v-model="vegetarian"
      class="relative transition-colors duration-200 ease-in-out inline-flex items-center h-6 rounded-full w-11 focus:outline-none"
      :class="vegetarian ? 'bg-pacific-600' : 'bg-gray-400'"
      v-slot="{ checked }"
    >
      <span
        class="inline-block w-4 h-4 transition duration-200 ease-in-out transform bg-white rounded-full"
        :class="{ 'translate-x-6': checked, 'translate-x-1': !checked }"
      />
    </Switch>
  </SwitchGroup>

  <div class="mb-12">
    <menu-category
      v-for="category in filteredMenu"
      :key="category.id"
      :category="category"
    />
  </div>
</template>

<script>
import menu from "../json/speisekarte.min.json";
import MenuCategory from "@/components/MenuCategory.vue";
import MenuItem from "@/components/MenuItem.vue";
import Modal from "@/components/Modal.vue";
import OrderList from "@/components/OrderList.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  name: "Menu",
  components: {
    MenuCategory,
    MenuItem,
    Modal,
    Switch,
    SwitchGroup,
    SwitchLabel,
    OrderList
  },
  data: function() {
    return {
      menu: menu,
      vegetarianMenu: [],
      randomFood: null,
      randomCategory: null,
      showRandomFoodModal: false,
      order: {}
    };
  },
  created: function() {
    this.setupVegetarianMenu();
  },
  computed: {
    filteredMenu: function() {
      if (this.$store.state.vegetarian) {
        return this.vegetarianMenu;
      }

      return this.menu;
    },
    vegetarian: {
      get() {
        return this.$store.state.vegetarian;
      },
      set(value) {
        this.$store.commit("setVegetarian", value);
      }
    }
  },
  methods: {
    setupVegetarianMenu: function() {
      let vm = menu;

      vm.forEach(category => {
        let filteredCategory = Object.assign({}, category);
        filteredCategory.menu = [];

        for (let categoryItem of category.menu) {
          if (categoryItem.vegetarian) {
            filteredCategory.menu.push(categoryItem);
          }
        }

        if (filteredCategory.menu.length > 0) {
          this.vegetarianMenu.push(filteredCategory);
        }
      });
    },
    getRandomFoods: function() {
      this.randomCategory = this.filteredMenu[
        Math.floor(Math.random() * this.filteredMenu.length)
      ];

      this.randomFood = this.randomCategory.menu[
        Math.floor(Math.random() * this.randomCategory.menu.length)
      ];
      this.showRandomFoodModal = true;
    }
  }
};
</script>
